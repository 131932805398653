<template>
  <div class="component-layout">
    <div class="bg-white p-4 rounded-md shadow-md relative">
      <CustomTable :data="keywords" :columns="columns" v-bind="tableProps" @page-change="handlePageChange" @sort="handleSort" @filter="handleFilter" @search="handleSearch" min-width="900px">
        <template #table-actions>
          <div class="flex items-center justify-between w-full mr-2">
            <div class="flex items-center space-x-2">
              <h1 class="text-base font-semibold">{{ t('dashboard.components.keywordAnalysis.title') }}</h1>
              <Tooltip :title="t('dashboard.components.keywordAnalysis.tooltips.exportTooltip')" v-if="keywords.length > 0">
                <template #content>
                  <div class="flex items-center">
                    <span v-if="keywords.length > 0" @click="exportToExcel" class="material-symbols-outlined text-secondary cursor-pointer !text-[22px] hover:text-primary">
                    export_notes
                    </span>
                  </div>
                </template>
              </Tooltip>
            </div>
          </div>
        </template>

        <template #extra-actions>
          <DateRangePicker v-model="datePickerModel" :currentYearOnly="true"/>
        </template>

        <template #number="{ item, index }">
          {{ calculateRank(index) }}
        </template>

        <template #yandex="{ item }">
          <div class="flex items-center justify-center">
            <Tooltip :title="getMaxPositionData(item, 1).formattedDate" :displayCondition="true">
              <template #content>
                <span :class="[getMaxPositionData(item, 1).class, 'cursor-pointer']">
                  {{ getMaxPositionData(item, 1).position }}
                </span>
              </template>
            </Tooltip>
          </div>
        </template>

        <template #google="{ item }">
          <div class="flex items-center justify-center">
            <Tooltip :title="getMaxPositionData(item, 2).formattedDate" :displayCondition="true">
              <template #content>
                <span :class="[getMaxPositionData(item, 2).class, 'cursor-pointer']">
                  {{ getMaxPositionData(item, 2).position }}
                </span>
              </template>
            </Tooltip>
          </div>
        </template>

        <template #bing="{ item }">
          <div class="flex items-center justify-center">
            <Tooltip :title="getMaxPositionData(item, 3).formattedDate" :displayCondition="true">
              <template #content>
                <span :class="[getMaxPositionData(item, 3).class, 'cursor-pointer']">
                  {{ getMaxPositionData(item, 3).position }}
                </span>
              </template>
            </Tooltip>
          </div>
        </template>

        <template #volume="{ item }">
          <div class="flex items-center justify-center">
            <span :class="isDateChange ? getVolumeDisplay(item).class : ''">
              {{ isDateChange ? getVolumeDisplay(item).volume : item.volume }}
            </span>
          </div>
        </template>

        <template #inContent="{ item }">
          <div class="flex items-center justify-center">
            <span>{{ item.entityCount }}</span>
          </div>
        </template>

        <template #competitorCount="{ item }">
          <div class="flex items-center justify-center">
            <span>{{ item.competitorCount }}</span>
          </div>
        </template>

        <template #source="{ item }">
          <div class="flex items-center justify-center">
            <span @click="item.type === 3 && toggleSimilarSourceModal()" :class="{ 'cursor-pointer text-blue-600': item.type === 3, 'cursor-default': item.type !== 1 }">
              {{ getSourceTypeLabel(item.type) }}
            </span>
          </div>
        </template>

        <template #reporting="{ item }">
          <div class="flex items-center justify-center space-x-4">
            <Tooltip :title="t('dashboard.components.keywordAnalysis.modals.positionGraph.title')" :display-condition="true">
              <template #content>
                <span class="material-symbols-outlined text-secondary !text-[16px] hover:text-primary cursor-pointer" @click="toggleMonthlyPositionModal(item)">
                  notes
                </span>
              </template>
            </Tooltip>
            <Tooltip :title="t('dashboard.components.keywordAnalysis.modals.volumeGraph.title')" :displayCondition="true">
              <template #content>
                <span class="material-symbols-outlined text-secondary !text-[16px] hover:text-primary cursor-pointer" @click="toggleMonthlyVolumeModal(item)">
                  query_stats
                </span>
              </template>
            </Tooltip>
          </div>
        </template>
      </CustomTable>

      <MonthlyKeywordVolumeModal v-model="showMonthlyVolumeModal" :item="selectedItem"/>
      <MonthlyKeywordPositionsModal v-model="showMonthlyPositionModal" :item="selectedItem"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { saveAs } from 'file-saver'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import CustomTable from '@/components/table/customTable.vue'
import DateRangePicker from '@/components/inputs/dateRangePicker.vue'
import MonthlyKeywordVolumeModal from './components/keywordModals/monthlyKeywordVolumeModal.vue'
import MonthlyKeywordPositionsModal from './components/keywordModals/monthlyKeywordPositionsModal.vue'
import Tooltip from '@/components/general/tooltip.vue'

dayjs.extend(utc)

const store = useStore()
const { t } = useI18n()

// State
const keywords = ref([])
const isLoading = ref(false)
const itemsPerPage = ref(30)
const totalCount = ref(0)
const pageCount = ref(0)
const currentPage = ref(1)
const showMonthlyVolumeModal = ref(false)
const showMonthlyPositionModal = ref(false)
const selectedItem = ref(null)
const datePickerModel = ref({ start: null, end: null })
const isDateChange = ref(false)
const sortKey = ref('')
const sortOrder = ref('DESC')
const searchQuery = ref('')
const selectedSourceFilter = ref('')

// Constants
const filterItems = [
  { id: '', name: t('dashboard.components.keywordAnalysis.sourceType.all'), default: true },
  { id: '1', name: t('dashboard.components.keywordAnalysis.sourceType.manuel') },
  { id: '2', name: t('dashboard.components.keywordAnalysis.sourceType.suggestion') },
  { id: '3', name: t('dashboard.components.keywordAnalysis.sourceType.similar') },
]

// Computed
const columns = computed(() => [
  { key: 'number', label: t('dashboard.components.keywordAnalysis.columns.number'), width: 'w-1/12' },
  { key: 'keywordName', label: t('dashboard.components.keywordAnalysis.columns.keywords'), width: 'w-2/12' },
  { key: 'volume', label: t('dashboard.components.keywordAnalysis.columns.searchVolume'), width: 'w-2/12', align: 'center', sortable: true },
  { key: 'source', label: t('dashboard.components.keywordAnalysis.columns.source'), width: 'w-1/12', align: 'center' },
  { key: 'yandex', label: t('dashboard.components.keywordAnalysis.columns.yandexRank'), width: 'w-1/12', align: 'center', sortable: true },
  { key: 'google', label: t('dashboard.components.keywordAnalysis.columns.googleRank'), width: 'w-1/12', align: 'center', sortable: true },
  { key: 'bing', label: t('dashboard.components.keywordAnalysis.columns.bingRank'), width: 'w-1/12', align: 'center', sortable: true },
  { key: 'inContent', label: t('dashboard.components.keywordAnalysis.columns.incontent'), width: 'w-1/12', align: 'center', sortable: true },
  { key: 'competitorCount', label: t('dashboard.components.keywordAnalysis.columns.competitorCount'), width: 'w-1/12', align: 'center', sortable: true },
  { key: 'reporting', label: t('dashboard.components.keywordAnalysis.columns.reporting'), width: 'w-1/12', align: 'center' },
])

const tableProps = computed(() => ({
  isLoading: isLoading.value,
  itemsPerPage: itemsPerPage.value,
  totalCount: totalCount.value,
  pageCount: pageCount.value,
  currentPage: currentPage.value,
  sortKey: sortKey.value,
  sortOrder: sortOrder.value,
  showFilter: true,
  filterOptions: [{
    id: 'source',
    icon: 'filter_alt',
    iconClass: 'material-symbols-outlined text-gray-600 !text-[22px]',
    showText: false,
    items: filterItems,
  }],
  minHeight: '300px',
  maxHeight: '300px',
}))

// Methods
const fetchData = async () => {
  isLoading.value = true
  try {
    const params = {
      assetId: store.state.assetId,
      page: currentPage.value - 1,
      ...(selectedSourceFilter.value && { source: selectedSourceFilter.value.source }),
      ...(sortKey.value && { sortKey: sortKey.value }),
      ...(sortOrder.value && { sortOrder: sortOrder.value }),
      ...(datePickerModel.value.start && datePickerModel.value.end && {
        startDate: dayjs(datePickerModel.value.start).utc().format(),
        endDate: dayjs(datePickerModel.value.end).utc().format(),
      }),
    }

    const response = await axios.get(dashboard.getKeywordAnalysis, { params })

    keywords.value = response.data.data.detail
    pageCount.value = response.data.data.pageCount
    totalCount.value = response.data.data.totalCount
  } catch (error) {
    console.error('Error fetching keywords:', error)
  } finally {
    isLoading.value = false
  }
}

const handlePageChange = (newPage) => {
  currentPage.value = newPage
  fetchData()
}

const handleSort = ({ key, order }) => {
  sortKey.value = key
  sortOrder.value = order
  fetchData()
}

const handleFilter = (selectedFilter) => {
  selectedSourceFilter.value = selectedFilter
  currentPage.value = 1
  fetchData()
}

const handleSearch = (query) => {
  searchQuery.value = query
  fetchData()
}

const getMaxPositionData = (item, engineId) => {
  if (!item?.positions || !Array.isArray(item.positions)) {
    return {
      position: isDateChange.value ? 'N/A' : '100+',
      class: 'text-gray-500',
      formattedDate: 'Belirlenen tarihler arasında ölçüm yapılmamıştır',
    }
  }

  const enginePositions = item.positions.filter(pos => pos.engine === engineId)
  if (enginePositions.length === 0) {
    return {
      position: isDateChange.value ? 'N/A' : '100+',
      class: 'text-gray-500',
      formattedDate: 'Belirlenen tarihler arasında ölçüm yapılmamıştır',
    }
  }

  const maxPosition = enginePositions.reduce((max, current) =>
      parseInt(current.position) < parseInt(max.position) ? current : max, enginePositions[0])

  const formattedDate = maxPosition.addDate ? dayjs(maxPosition.addDate).format('YYYY-MM-DD') : ''

  let position = maxPosition.position === '0' ? '100+' : maxPosition.position || 'N/A'
  const positionDifference = parseInt(maxPosition.positionDifference) || 0

  if (position === 'N/A' || parseInt(position) > 100) {
    position = '100+'
  } else if (isDateChange.value) {
    const sign = positionDifference > 0 ? '+' : ''
    position += ` (${sign}${positionDifference})`
  }

  const positionClass = (position === '100+' || parseInt(position) > 100)
      ? 'text-gray-400'
      : positionDifference > 0
          ? 'text-green-500 font-bold'
          : (positionDifference < 0 ? 'text-red-500 font-bold' : '')

  const tooltipText = formattedDate ? `Son Ölçüm Tarihi: ${formattedDate}` : ''

  return { position, class: positionClass, formattedDate: tooltipText }
}

const getVolumeDisplay = (item) => {
  const volume = item.volume || 0
  const volumeChangePercentage = parseFloat(item.volumeChangePercentage) || 0

  let displayText = volume.toString()
  let volumeClass = ''

  if (isDateChange.value && volumeChangePercentage !== 0) {
    const sign = volumeChangePercentage > 0 ? '+' : ''
    displayText += ` (${sign}${volumeChangePercentage}%)`
    volumeClass = volumeChangePercentage > 0 ? 'text-green-500 font-bold' : 'text-red-500 font-bold'
  }

  return { volume: displayText, class: volumeClass }
}

const getSourceTypeLabel = (type) => {
  const sourceTypes = {
    1: 'manuel',
    2: 'suggestion',
    3: 'similar',
  }
  return t(`dashboard.components.keywordAnalysis.sourceType.${sourceTypes[type] || 'unknown'}`)
}

const exportToExcel = () => {
  const params = {
    assetId: store.state.assetId,
    page: currentPage.value - 1,
    download: true,
    ...(selectedSourceFilter.value && { source: selectedSourceFilter.value.source }),
    ...(sortKey.value && { sortKey: sortKey.value }),
    ...(sortOrder.value && { sortOrder: sortOrder.value }),
    ...(datePickerModel.value.start && datePickerModel.value.end && {
      startDate: dayjs(datePickerModel.value.start).utc().format(),
      endDate: dayjs(datePickerModel.value.end).utc().format(),
    }),
  }

  axios.get(dashboard.getKeywordAnalysis, { params, responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const fileName = `keyword_analysis_${dayjs().format('YYYY-MM-DD')}.xlsx`
        saveAs(blob, fileName)
      })
      .catch((error) => {
        console.error('Dosya indirilemedi:', error)
      })
}

const toggleMonthlyVolumeModal = (item) => {
  selectedItem.value = { id: item.keywordId, name: item.keywordName }
  showMonthlyVolumeModal.value = true
}

const toggleMonthlyPositionModal = (item) => {
  selectedItem.value = { id: item.keywordId, name: item.keywordName }
  showMonthlyPositionModal.value = true
}

const handleDateChange = (newDateRange) => {
  datePickerModel.value = newDateRange
  currentPage.value = 1
  isDateChange.value = true
  fetchData()
}

const calculateRank = (index) => (currentPage.value - 1) * itemsPerPage.value + index + 1

// Lifecycle hooks
onMounted(() => {
  fetchData()
})

watch(datePickerModel, (newValue, oldValue) => {
  if (newValue.start !== oldValue.start || newValue.end !== oldValue.end) {
    if (newValue.start && newValue.end) {
      handleDateChange(newValue)
    } else if (newValue.start === null && newValue.end === null) {
      isDateChange.value = false
      fetchData()
    }
  }
})
</script>